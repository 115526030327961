'use client';

import React from 'react';
import { MdChevronRight } from 'react-icons/md';
import { FcFlashOn } from 'react-icons/fc';
import FlashSaleLottie from '../../../assets/lotties/FlashSale.json';
import useAuth from '@/contexts/AuthProvider';
import { get } from 'lodash';
import { ProductSummary } from '@/services/ecommerce/utils/helper';
import LottiePlayer from '../LottiePlayer/LottiePlayer';
import generateBlockLink from '@/services/ecommerce/utils/LinkGenerator';
import Link from 'next/link';

interface FlashSaleButtonProps {
	type?: 'category' | 'cart';
}

const FlashSaleButton = ({ type = 'category' }: FlashSaleButtonProps) => {
	const { FlashSale } = useAuth();
	const styles = {
		button: {
			marginTop: '16px',
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			backgroundColor: 'white',
			cursor: 'pointer',
			transition: 'background-color 0.3s',
			borderTop: '1px solid #e5e7eb',
			borderBottom: '1px solid #e5e7eb',
			borderLeft: type === 'cart' ? '1px solid #e5e7eb' : 'none',
			borderRight: type === 'cart' ? '1px solid #e5e7eb' : 'none',
			padding: type === 'cart' ? '0px 7px 0px 0px' : '7px 0px 7px 20px',
			height: 50
		},
		content: {
			display: 'flex',
			alignItems: 'center',
			gap: '8px',
			padding: type === 'cart' ? 10 : '7px 7px 7px 0'
		},
		zapIcon: {
			width: '32px',
			height: '32px'
		},
		flashSaleText: {
			fontWeight: 'bold',
			letterSpacing: '-0.025em'
		},
		countWrapper: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			gap: '8px'
		},
		countBorder: {
			borderRadius: type === 'category' ? 4 : 99999,
			background: 'linear-gradient(91.69deg, #F34128 0.61%, #080808 79.15%)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: 55,
			height: 24
		},
		count: {
			borderRadius: type === 'category' ? 4 : 99999,
			width: 453,
			height: 22,
			background: 'white',
			border: '1px solid transparent',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			margin: 1
		},
		chevronIcon: {
			width: '24px',
			height: '24px',
			color: '#9ca3af'
		}
	};
	const isEnableFlashSale = FlashSale?.total || 0;
	const product = get(FlashSale, 'products[0]', {});
	const { discountPercentage } = ProductSummary(get(product, 'pv[0]', {}));
	const { link } = generateBlockLink(FlashSale?.block) || '/';

	return (
		<>
			{isEnableFlashSale === 0 ? null : (
				<Link href={(link as string) || '/'}>
					<div
						style={{
							...styles.button,
							borderRadius: type === 'cart' ? 6 : 0,
							boxShadow: type === 'cart' ? '2px 2px 8px 0px #0000000A' : 'none'
						}}>
						<div style={styles.content}>
							<FcFlashOn size={32} style={styles.zapIcon} />
							<div style={styles.countWrapper as any}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center'
									}}>
									<LottiePlayer
										style={styles.flashSaleText}
										animationData={FlashSaleLottie}
										loop={true}
										autoplay={true}
										width='100%'
										height='24px'
									/>
									{type == 'cart' ? (
										<span className='text-10'>
											<div>
												Save up to <strong className='text-error'>{discountPercentage}%</strong> 🔥
											</div>
										</span>
									) : null}
								</div>
								<div style={styles.countBorder}>
									<div style={styles.count}>
										{type == 'cart' ? (
											<span className='text-12'>
												<span
													style={{
														fontWeight: 600,
														height: 8,
														width: 8,
														backgroundColor: '#F34128',
														minHeight: 1,
														minWidth: 1,
														display: 'inline-block',
														marginRight: 2,
														borderRadius: '50%'
													}}></span>
												Live
											</span>
										) : (
											<span className='text-red-500'>
												{isEnableFlashSale === 1000 ? '1000+' : isEnableFlashSale}
											</span>
										)}
									</div>
								</div>
							</div>
						</div>

						<MdChevronRight style={styles.chevronIcon} className={'arogga-sidebar-submenu-arrow'} size={24} />
					</div>
				</Link>
			)}
		</>
	);
};

export default FlashSaleButton;
